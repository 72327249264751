import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Row, Col, Nav, Tab, Sonnet } from "react-bootstrap"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const NewStudentPage = () => (
	<Layout>
		<SEO
			title="New Student Resource"
			description="We are very excited to offer monthly online group consults for all NARM Training Participants, Graduates, and Training Assistants."
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1>New Student Resource</h1>
				</div>
			</div>
		</div>
		<div class="page-content narm-student">
			<div class="container">
				<Tab.Container id="left-tabs-example" defaultActiveKey="resource-1">
					<Row>
						<Col sm={4}>
							<Nav variant="pills" className="flex-column">
								<Nav.Item>
									<Nav.Link className="narm-link-tab" eventKey="resource-1">
										NARM Online Case Consult Groups
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className="narm-link-tab" eventKey="resource-2">
										NARM Practitioner Training Manuals
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className="narm-link-tab" eventKey="resource-3">
										NARM Practitioner Training Documents
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col sm={8}>
							<Tab.Content>
								<Tab.Pane eventKey="resource-1">
									<h2>
										NARM Online Group Consults – Monthly with Brad and Stefanie
										in 2020
									</h2>
									<p>
										We are very excited to offer monthly online group consults
										for all NARM Training Participants, Graduates, and Training
										Assistants. Anyone who is currently participating or has
										completed the NARM Training can continue their learning
										through these Group Consults!
									</p>
									<p>
										Group Consults are a great way to stay connected with and
										deepen you mastery of NARM. Stefanie and Brad will be making
										these available to you in the online format through the Zoom
										platform on a monthly basis. Below is the schedule for 2020.
									</p>
									<p>
										<strong>
											2020 Group Consult Schedule for Current NARM Students
										</strong>
									</p>
									<p>
										<strong>
											2nd Tuesdays of the month from 8-10 am Pacific, 10-12
											Central, 11-1 Eastern
										</strong>
									</p>
									<p>
										January 14, 2020 with Stefanie
										<br />
										February 11, 2020 with Brad
										<br />
										March 10, 2020 with Stefanie
										<br />
										April 14, 2020 with Brad
										<br />
										May 12, 2020 with Stefanie
										<br />
										June 9, 2020 with Brad
										<br />
										July 14, 2020 with Stefanie
										<br />
										August 11, 2020 with Brad
										<br />
										September 8, 2020 with Stefanie
										<br />
										October 13, 2020 with Brad
										<br />
										November 10, 2020 with Stefanie
										<br />
										December 8, 2020 with Brad
									</p>
									<a href="https://narm.kartra.com/page/VMp146" target="_blanl">
										Click Here to Register for Student Group Consults
									</a>
									<p className="mt-4">
										<strong>2020 Group Consult Schedule for Graduates</strong>
									</p>
									<p>
										<strong>
											Usually 3rd Tuesdays of the month from 8-10 am Pacific,
											10-12 Central, 11-1 Eastern
										</strong>
									</p>
									<p>
										January 21, 2020 with Brad
										<br />
										February 18, 2020 with Stefanie
										<br />
										March 10, 2020 with Marcia
										<br />
										March 17, 2020 with Brad
										<br />
										April 21, 2020 with Stefanie
										<br />
										April 21, 2020 with Marcia
										<br />
										May 5, 2020 with Marcia
										<br />
										May 19, 2020 with Brad
										<br />
										June 16, 2020 with Stefanie
										<br />
										July 21, 2020 with Brad
										<br />
										August 18, 2020 with Stefanie
										<br />
										September 15, 2020 with Brad
										<br />
										October 20, 2020 with Stefanie
										<br />
										November 17, 2020 with Brad
										<br />
										December 15, 2020 with Stefanie
									</p>
									<a href="https://narm.kartra.com/page/ixb155" target="_blanl">
										Click Here to Register for Grad Group Consults
									</a>
									<p className="mt-4">
										As you may know, the intention for our online group consults
										is to support you in integrating NARM into your clinical
										practice. You're welcome to bring your cases to these
										consult groups, and Brad/Stefanie will facilitate reflection
										on how you can apply NARM with your clients. Many
										participants bring other NARM-related questions, things that
										come up between training modules or during sessions. And
										lastly, some people just enjoy listening to others and
										engaging with questions and reflections.
									</p>
									<p>
										Our Group Consults are be limited to 12 participants. The
										minimum needed to run will be 5 participants, and you will
										be refunded if we don’t run the group. We’d encourage you to
										sign up early to reserve your place, as they do sell out. If
										the Group Consult you're interested in is full, please add
										yourself to the waitlist. If a spot becomes available, we
										will let you know. Group Consults cost $75 for the 2-hour
										group and will count as 1 credit toward your case consult
										training requirement (if you are still in need of them).
									</p>
									<p>
										Please note that if you do not attend a group that you have
										paid for there will be no recording available and the fees
										are non-refundable. To participate you must attend live.
									</p>
									<p>
										<strong>Refund policy:</strong>
									</p>
									<p>
										In order to be eligible for a refund, you must contact
										admin@narmtraining.com before 5 days prior to the Group
										Consult for which you have registered.
									</p>
									<p>
										We look forward to connecting with you all in 2020! We are
										excited to be coming together as an international NARM
										community to share our work together, support one another,
										and continue deepening our learning and skill with NARM.
									</p>
								</Tab.Pane>
								<Tab.Pane eventKey="resource-2">
									<h2>NARM PRACTITIONER TRAINING MANUALS</h2>
									<p>
										Please Note:
										<i>
											The NARM Training Institute is making these NARM Training
											Manuals available online as a resource for continued
											learning of NARM. These training manuals are to be
											accessed ONLY by current NARM students and NARM graduates.
											The training manuals contain copyrighted information and
											must NOT be shared, copied, edited or distributed under
											any circumstances. We ask that you please respect this
											agreement. If this agreement is violated, we will be
											forced to remove these training manuals as a resource for
											continued learning of NARM.
										</i>
									</p>
									<Row>
										<Col md={6} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/module-for-training-manual-1-square-1-300x250.png"
												class="img-fluid mb-4"
												alt="Narm Practitioner Training 1"
											/>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="/narm-practitioner-training-manual-module-1"
											>
												Access Manual
											</a>
										</Col>
										<Col md={6} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/module-2-square-1-300x250.png"
												class="img-fluid mb-4"
												alt="Narm Practitioner Training 2"
											/>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="/narm-practitioner-training-manual-module-2"
											>
												Access Manual
											</a>
										</Col>
										<Col md={6} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/module-3-square-300x250.png"
												class="img-fluid mb-4"
												alt="Narm Practitioner Training 3"
											/>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="/narm-practitioner-training-manual-module-3"
											>
												Access Manual
											</a>
										</Col>
										<Col md={6} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/module-4-square-300x250.png"
												class="img-fluid mb-4"
												alt="Narm Practitioner Training 4"
											/>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="/narm-practitioner-training-manual-module-4"
											>
												Access Manual
											</a>
										</Col>
									</Row>
								</Tab.Pane>
								<Tab.Pane eventKey="resource-3">
									<h2>NARM PRACTITIONER TRAINING DOCUMENTS</h2>
									<p>
										Please Note:
										<i>
											The NARM Training Institute is making these NARM Training
											Documents available online as a resource for continued
											learning of NARM. These training documents are to be
											accessed ONLY by current NARM students and NARM graduates.
											The training documents contain copyrighted information and
											must NOT be shared, copied, edited or distributed under
											any circumstances. We ask that you please respect this
											agreement. If this agreement is violated, we will be
											forced to remove these training documents as a resource
											for continued learning of NARM.
										</i>
									</p>
									<Row>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">NARM Student Policy Handbook</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/ap7x6f6yrlzkn5t/NARM%20Student%20Policy%20Handbook%20-%20Rev.%202_2020.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">NARM Consultation Log</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/dmycmgrio8mlu0h/NARM%20Consultation%20Log.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												NARM Experiential Consult Worksheet
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/udk1hyddg1dioza/NARM%20Experiential%20Consult%20Worksheet%20-%2011_2018.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">NARM Distress & Healing Cycles</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/f102x6v4f15urds/Distress_HealingCycles.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												NARM Personality Spectrum Worksheet
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/xlwhxtwranskrk3/NARMPersonalitySpectrumWorksheet.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">NARM Clinical Protocol</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/ysxuxoyzwci3j8f/NARM%20Clinical%20Protoco.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">Distortions of the Life Force</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/jfmklkx0whwbey9/DistortionsOfLifeForce.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">Distortions of the Life Force</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/jfmklkx0whwbey9/DistortionsOfLifeForce.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">Core Capacities For Well-Being</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/kjqxgodkhbl6kd0/CoreCapacitiesForWellBeing.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">Formation Of Adaptive Styles</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/jqlqsc5xfdtie4v/FormationOfAdaptiveStyles.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												Core Difficulties And Strategies to Protect Attachment
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/pjmf6h8j9n4mpzt/CoreDifficultiesAndProtectAttachment.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												Contact Information for Student Consultations
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/gixw4cziww7fq0s/NARM%20Consultation%20Provider%20Contact%20Information.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												Brad’s Graduate Thesis: Trauma & Civilization
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/miqczgsrxfdw3gp/Trauma-Civilization.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												NARM Therapist & Practitioner Requirements
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/4yqdoa24gnzllzl/NARM%20Therapist%20%26%20Practitioner%20Requirements.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												Training Assistant Pool Application Information
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/d0aj8memztjbb80/Training%20Assistant%20Pool%20Application%20Inquiry%20Email%20%20%281%29.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
										<Col md={4} className="text-center mb-5">
											<LazyLoadImage
												effect="blur"
												src="/assets/img/pdf.png"
												class="img-fluid mb-2"
												alt="PDF File"
											/>
											<h4 className="mb-4">
												Training Assistant Pool Application
											</h4>
											<a
												className="btn btn-primary inverted shadow arrow"
												href="https://www.dropbox.com/s/ydtehnnlgnxyi70/NARM%20Training%20Assistant%20Application%20%28rev.11_2019%29.pdf?dl=0"
												target="_blank"
											>
												View
											</a>
										</Col>
									</Row>
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>
		</div>
	</Layout>
)

export default NewStudentPage
